import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "@/components/ui/menubar";
import Link from "next/link";

export default function ShadcnMenubar() {
  return (
    <Menubar id="navbar-collapse-animation" className="hidden md:flex bg-white">
      <MenubarMenu>
        <MenubarTrigger>
          <Link href="/" className="text-black">
            Home
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger>
          <Link href="/about-us/" className="text-black">
            About us
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger>Products</MenubarTrigger>
        <MenubarContent>
          <MenubarItem>
            <Link href="/water-slides/" className="text-black">
              Water Slides
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/aqua-towers/" className="text-black">
              Aqua Towers
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/spray-attractions/" className="text-black">
              Spary Attractions
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/water-movers/" className="text-black">
              Water Mover
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/resorts-campgrounds/" className="text-black">
              Resorts & Campgrounds
            </Link>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger>Projects</MenubarTrigger>
        <MenubarContent>
          <MenubarItem>
            <Link href="/america/" className="text-black">
              America
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/europe/" className="text-black">
              Europe
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/asia-australia/" className="text-black">
              Asia & Australia
            </Link>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger>
          <Link href="/updates/" className="text-black">
            Updates
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger>
          <Link href="/downloads/" className="text-black">
            Downloads
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
      {/* <MenubarMenu>
        <MenubarTrigger>Downloads</MenubarTrigger>
        <MenubarContent>
          <MenubarItem>
            <Link href="/catalogues" className="text-black">
              Catalogues
            </Link>
          </MenubarItem>
          <MenubarItem>
            <Link href="/videos/" className="text-black">
              Videos
            </Link>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu> */}
      <MenubarMenu>
        <MenubarTrigger>
          <Link href="/contact-us" className="text-black">
            Contact Us
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
    </Menubar>
  );
}
