"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu";
import { navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";

import ShadcnMenubar from "@/components/ShadcnMenubar";
import HeaderMobile from "@/components/header-mobile";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <header
      className={`sticky top-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full py-4 sm:py-2 transition-colors duration-300 ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      <nav
        className=" w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <Link className="flex-none" href="/" aria-label="Brand">
            <img
              width={320}
              height={130}
              src="/images/logo.png"
              className="object-cover w-36 md:w-56"
            />
          </Link>

          <div className="md:hidden order-3">
            <img
              src="/images/polin-logo.png"
              alt="a Polin Group Company"
              className="object-cover"
              width={180} // Adjust the width as needed
              height={30} // Adjust the height as needed
            />
          </div>
        </div>

        <ShadcnMenubar />

        <div className="hidden md:block md:order-none">
          <img
            src="/images/polin-logo.png"
            className="object-cover text-center"
            width={230}
            height={45}
            alt="a polin Group Company"
          />
        </div>
        <HeaderMobile />
      </nav>
    </header>
  );
};

export default Header;
