import { Icon } from "@iconify/react";

import { SideNavItem } from "./types";

export const SIDENAV_ITEMS: SideNavItem[] = [
  {
    title: "Home",
    path: "/",
    icon: <Icon icon="lucide:home" width="24" height="24" />,
  },
  {
    title: "About Us",
    path: "/about-us",
    icon: <Icon icon="lucide:home" width="24" height="24" />,
  },
  {
    title: "Products",
    path: "#",
    icon: <Icon icon="lucide:folder" width="24" height="24" />,
    submenu: true,
    subMenuItems: [
      { title: "Water Slides", path: "/water-slides" },
      { title: "Aqua Towers", path: "/aqua-towers" },
      { title: "Spray Attractions", path: "/spray-attractions" },
      { title: "Water movers", path: "/water-movers" },
      { title: "Resorts & Campgrounds", path: "/resorts-campgrounds" },
    ],
  },
  {
    title: "Projects",
    path: "#",
    icon: <Icon icon="lucide:folder" width="24" height="24" />,
    submenu: true,
    subMenuItems: [
      { title: "America", path: "/america" },
      { title: "Europe", path: "/europe" },
      { title: "Australia & Asia", path: "/asia-australia" },
    ],
  },
  {
    title: "Updates",
    path: "/updates",
    icon: <Icon icon="lucide:mail" width="24" height="24" />,
  },

  {
    title: "Contact Us",
    path: "/contact-us",
    icon: <Icon icon="lucide:help-circle" width="24" height="24" />,
  },
];
